import {
  CONTACT_INFO_PATH,
  HOME_ADDRESS_PATH,
  BUDGET_PATH,
  RECOMMENDED_PACKAGE_PATH,
  CREATE_ACCOUNT_PATH,
  TYPEFORM_PATH,
} from ".././pages/designProfile/constants/paths";
import { DesignProfileNavigation } from ".././types/DesignProfile";
import { DESIGN_PROFILE_VERSION_6 } from ".././util/constants/designProfileVersionIds";

// DO NOT EDIT OLD VERSIONS. VERSIONS #'S ARE STORED IN FIRESTORE
// EACH SUBSEQUENT VERSION NEEDS TO INCREMENT IN VERSION # AND NEEDS
// IT'S OWN FILE IN src/designProfileVersions

// Version 6: shorter Typeform
export const designProfileVersion6: DesignProfileNavigation = {
  versionId: DESIGN_PROFILE_VERSION_6,
  paths: [
    {
      path: HOME_ADDRESS_PATH,
      component: "AddressInputPage",
      title: "First, tell us where your outdoor space is",
      button_text: "Submit",
      alt_button_text: null,
      next: TYPEFORM_PATH,
      previous: null,
      header_page: 0,
      completion_number: 1,
    },
    {
      path: TYPEFORM_PATH,
      component: "DesignProfileTypeform",
      title: "",
      button_text: null,
      alt_button_text: null,
      next: CONTACT_INFO_PATH,
      previous: HOME_ADDRESS_PATH,
      header_page: 2,
      completion_number: 2,
    },
    {
      path: CONTACT_INFO_PATH,
      component: "DesignProfileEmailPhoneInputPage",
      title: "Almost done. Let's save your results.",
      subtitle:
        "Sign up now and you'll receive a unique 30% discount code on your first order.*",
      button_text: "Next",
      alt_button_text: null,
      next: RECOMMENDED_PACKAGE_PATH,
      previous: BUDGET_PATH,
      header_page: 4,
    },
    {
      path: RECOMMENDED_PACKAGE_PATH,
      component: "RecommendedPackagePage",
      title: "We recommend...",
      button_text: "PURCHASE",
      alt_button_text: "Questions? Talk to Our Team",
      next: CREATE_ACCOUNT_PATH,
      previous: CONTACT_INFO_PATH,
      header_page: 4,
    },
  ],
};
