import { useAppDispatch, useAppSelector } from "../../hooks";
import { addToAddOns, removeFromAddOns } from "../../slices/productSlice";
import { useEffect, useState } from "react";
import { PriceObject } from "../../types/Prices";
import environmentConstant from "../constants/environmentConstant";

const premiumProductId =
  process.env.REACT_APP_ENV === environmentConstant.PRODUCTION
    ? "prod_M3x5txOJjeIVOm"
    : "prod_M3wtH53kjnFg9q";

const premiumSkuId =
  process.env.REACT_APP_ENV === environmentConstant.PRODUCTION
    ? "price_1LLpBGA7w7Y5M9CKZ1SdbVcq"
    : "price_1LLozoA7w7Y5M9CKJslMNG6k";

function containsPremiumSku(skus: PriceObject[]): boolean {
  return skus.findIndex(sku => sku.id === premiumSkuId) >= 0;
}

export function usePremiumPackage() {
  const { addOnSkus } = useAppSelector(state => state.products);
  const dispatch = useAppDispatch();

  const [isPremium, setIsPremium] = useState(containsPremiumSku(addOnSkus));

  useEffect(() => {
    setIsPremium(containsPremiumSku(addOnSkus));
  }, [setIsPremium, addOnSkus]);

  const { products } = useAppSelector(state => state.products);
  const premiumSku = products
    .find(product => product.id === premiumProductId)
    ?.skus.data.find(sku => sku.id === premiumSkuId);

  if (!premiumSku)
    throw new Error("Could not find SKU for premium package in Stripe");

  function set(setter: boolean) {
    if (!premiumSku) return;
    if (setter === true) {
      dispatch(addToAddOns({ addOn: premiumSku, repeatable: false }));
    } else {
      dispatch(removeFromAddOns({ addOn: premiumSku }));
    }
  }

  return {
    isPremium,
    premiumSku,
    setIsPremium: set,
  };
}
