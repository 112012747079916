import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import firebase from "firebase/compat/app";
import {
  CREATE_CURRENT_USER_TERMS_OF_SERVICE,
  GET_USER_TERMS_OF_SERVICE,
} from "./graphqlQueries";

export const BANYAN_BASE_URL =
  process.env["REACT_APP_BANYAN_BASE_URL"] || "http://localhost:3000/";

type FindAllUserTermsOfServiceInput = {
  userId: string;
};

type CreateCurrentUserTermsOfServiceInput = {
  dateOfAgree: any;
  tosAgreed: boolean;
  userId: string;
};

type UserTermsOfService = {
  tosAgreed: boolean;
  dateOfAgree: any;
  typeOfTos: string;
  userId: string;
};

enum BanyanApiTags {
  UserTerms = "USER_TERMS",
}

export const banyanAPI = createApi({
  reducerPath: "banyanAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: BANYAN_BASE_URL,
    prepareHeaders: async headers => {
      const user = firebase.auth()?.currentUser;
      if (user) {
        headers.set("Authorization", `Bearer ${await user.getIdToken()}`);
      }

      return headers;
    },
  }),
  tagTypes: [BanyanApiTags.UserTerms],
  endpoints: builder => ({
    getUserTermsOfService: builder.query<
      UserTermsOfService,
      FindAllUserTermsOfServiceInput
    >({
      query: input => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_USER_TERMS_OF_SERVICE,
          variables: {
            input,
          },
        },
      }),
      providesTags: [BanyanApiTags.UserTerms],
      transformResponse: (response: any) => response.data.findTermsOfService,
    }),
    createUserTermsOfService: builder.mutation<
      UserTermsOfService,
      CreateCurrentUserTermsOfServiceInput
    >({
      query: input => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: CREATE_CURRENT_USER_TERMS_OF_SERVICE,
          variables: {
            input,
          },
        },
      }),
      invalidatesTags: [BanyanApiTags.UserTerms],
      transformResponse: (response: any) =>
        response.data.createCurrentUserTermsOfService,
    }),
  }),
});
