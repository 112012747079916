import * as React from "react";
import { useDesignProfile } from "./useDesignProfile";
import { useLogError } from "../../util";
import { intercomBaseConfig } from "../../util/Intercom";
import { useDataLayer } from "../../data";
import { sha256ToHexString } from "../../util/sha256";
import firebase from "firebase/compat/app";
import { DesignProfile, UpdateDesignProfileDto } from "../../api/apiTypes";
import { DesignProfileVersionId } from "../../types/DesignProfile";
import _ from "lodash";

export interface DesignProfileProviderProps {
  user: firebase.User;
  designProfileVersionId?: DesignProfileVersionId;
}

export interface DesignProfileCtxValue {
  user: firebase.User;
  designProfile: DesignProfile | undefined;
  error: Error | null;
  updateDesignProfile: (
    updatedDesignProfile: UpdateDesignProfileDto
  ) => Promise<boolean | undefined>;
  updateDesignProfileDebounced: _.DebouncedFunc<
    (
      updatedDesignProfile: UpdateDesignProfileDto
    ) => Promise<boolean | undefined>
  >;
  isLoading: boolean;
  isFetching: boolean;
  isUpdating: boolean;
  designProfileVersionId?: DesignProfileVersionId;
}

const DesignProfileCtx = React.createContext<DesignProfileCtxValue>({
  user: {} as firebase.User,
  designProfile: undefined,
  error: null,
  isLoading: false,
  isFetching: false,
  isUpdating: false,
  updateDesignProfile: async (updatedDesignProfile: UpdateDesignProfileDto) => {
    return Promise.resolve(undefined);
  },
  updateDesignProfileDebounced: _.debounce(
    async (updatedDesignProfile: UpdateDesignProfileDto) => {
      return Promise.resolve(undefined);
    }
  ),
});

const useDesignProfileCtx = () => {
  return React.useContext(DesignProfileCtx);
};

const DesignProfileProvider: React.FC<DesignProfileProviderProps> = ({
  user,
  children,
  designProfileVersionId,
}) => {
  const dataLayer = useDataLayer();
  const {
    designProfile,
    error,
    updateDesignProfile,
    isLoading,
    isFetching,
    isUpdating,
  } = useDesignProfile({ user, versionId: designProfileVersionId });
  const updateDesignProfileDebounced = _.debounce(updateDesignProfile, 750);

  useLogError(error);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(onDsContextUpdate, [designProfile]);

  return (
    <DesignProfileCtx.Provider
      value={{
        error: error,
        user: user,
        designProfile,
        updateDesignProfile,
        isLoading,
        isFetching,
        isUpdating,
        designProfileVersionId,
        updateDesignProfileDebounced,
      }}
    >
      {children}
    </DesignProfileCtx.Provider>
  );

  function onDsContextUpdate() {
    const name = `${designProfile?.contactInformation?.firstName} ${designProfile?.contactInformation?.lastName}`;
    const email = designProfile?.contactInformation?.email;

    if (!dataLayer.tracking_id && email) {
      void async function() {
        dataLayer.tracking_id = await sha256ToHexString(
          email.trim().toLowerCase()
        );
      };
    }

    window.Intercom("update", {
      ...intercomBaseConfig,
      email: email,
      user_id: designProfile?.userId,
      name: name.length > 1 ? name : !!email ? email : "Zenian",
    });
  }
};

export { DesignProfileCtx, DesignProfileProvider, useDesignProfileCtx };
