import { PromotionalCodeStatus } from "../../../services/promotionalCodes";
import { PromotionalCode } from "../../../api/apiTypes";
import {
  SegmentClient,
  SegmentForms,
  SegmentInputTypes,
} from "../../../util/Segment";
import { lookupPromoCode } from "./lookupPromoCode";
import { promoCodeIsOnlyFreeExpedited } from "./promoCodeIsOnlyFreeExpedited";
import { Product } from "../../../types/Products";

interface HandleReferralCodeSubmit {
  code: string;
  segment: SegmentClient;
  segmentFlow: string;
  referralCodeCollection: string[];
  setReferralCodeError: (arg: boolean) => void;
  setInputReferralCode: (code: string) => void;
  setPromotionalCode: (code: PromotionalCode | null) => void;
  setInvalidCodeMessage: (message: string) => void;
  isStarterPackage?: boolean;
  products: Product[];
  selectedProduct?: Product | null;
}

export const handleReferralCodeSubmit = async ({
  code,
  segment,
  segmentFlow,
  referralCodeCollection,
  setReferralCodeError,
  setInputReferralCode,
  setPromotionalCode,
  setInvalidCodeMessage,
  isStarterPackage,
  products,
  selectedProduct,
}: HandleReferralCodeSubmit) => {
  const resetDefaults = () => {
    setReferralCodeError(false);
    setInputReferralCode("");
    setPromotionalCode(null);
    setInvalidCodeMessage("");
  };
  segment.trackFormFieldFilled({
    flow_name: segmentFlow,
    field_name: "Referral Input",
    form_name: SegmentForms.REFERRAL_SUBMISSION,
    input_type: SegmentInputTypes.TEXT,
    text_value: code,
  });

  const promoCode: PromotionalCode =
    (code && (await lookupPromoCode(code))) || null;

  const isExpiredMarketingCode: boolean =
    promoCode &&
    promoCode.status === PromotionalCodeStatus.EXPIRED &&
    promoCode.category === "MARKETING";
  const isMaxRedemptionsExceededMarketingCode: boolean =
    promoCode &&
    promoCode.status === PromotionalCodeStatus.MAX_REDEMPTIONS_EXCEEDED &&
    promoCode.category === "MARKETING";

  resetDefaults();

  let passedValidation: boolean = false;
  const isSmallSpacePackage = selectedProduct?.name === "Small Space Package";
  const isBotanicalPackage = selectedProduct?.name === "Botanical Package";

  if (
    isBotanicalPackage ||
    (isStarterPackage &&
      !promoCodeIsOnlyFreeExpedited(promoCode, products) &&
      !isLowesCode(promoCode.code))
  ) {
    // Only Free Expedited can be applied to Starter Packages
    setReferralCodeError(true);
    setInvalidCodeMessage(`${code} is not valid for this package.`);
    passedValidation = false;
  } else if (isSmallSpacePackage && code.includes("GETSTARTED")) {
    setReferralCodeError(true);
    setInvalidCodeMessage(`${code} is not valid for this package.`);
    passedValidation = false;
  } else if (promoCode && promoCode.status === PromotionalCodeStatus.USABLE) {
    // This is a valid promo code
    setPromotionalCode(promoCode);
    passedValidation = true;
  } else if (referralCodeCollection.includes(code)) {
    // This is a valid referral code (referral codes are different than promo codes)
    setInputReferralCode(code);
    passedValidation = true;
  } else {
    // Something else went wrong
    setReferralCodeError(true);
    if (isExpiredMarketingCode) {
      setInvalidCodeMessage(
        `${code} is no longer valid and cannot be applied to your order.`
      );
    } else if (isMaxRedemptionsExceededMarketingCode) {
      setInvalidCodeMessage(
        `The number of redemptions for this promo code has reached its limit. Please enter a valid promo code.`
      );
    }
    passedValidation = false;
  }

  trackFormSubmit(segment, segmentFlow, passedValidation);
};

function trackFormSubmit(
  segment: SegmentClient,
  segmentFlow: string,
  passedValidation: boolean
) {
  segment.trackFormSubmitted({
    flow_name: segmentFlow,
    form_name: SegmentForms.REFERRAL_SUBMISSION,
    passed_validation: passedValidation,
  });
}

function isLowesCode(code: string) {
  return code.toLowerCase().startsWith("yzlo");
}
