import { Box, Grow, makeStyles, Theme } from "@material-ui/core";
import {
  GenericLoadingSpinner,
  YZThemeProvider,
} from "@yardzen-inc/react-common";
import { useTreatmentNoReadyCheck } from "@yardzen-inc/react-split";
import React from "react";
import { useHistory } from "react-router";
import { CenterFullScreen, DefaultHeader, HeaderBase } from "../../components";
import { CheckoutForm } from "../../components/checkout/CheckoutForm";
import { getEmail } from "../../components/checkout/util/getEmail";
import {
  AnonymousUserControlWrapper,
  useDesignProfileCtx,
} from "../../components/designProfile";
import { useAppSelector } from "../../hooks";
import { UserCtx } from "../../util";
import { HIDE_CHECKOUT_SIDEBAR } from "../../util/Split/splitTreatments";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

export const PackageFlowCheckoutPage = () => {
  const { products } = useAppSelector(state => state.products);
  const history = useHistory();
  const { designProfile } = useDesignProfileCtx();
  const userContext = React.useContext(UserCtx);
  const { firstName, lastName } = useAppSelector(state => state.checkout);
  const email = getEmail({ userContext, designProfile });
  const {
    selectedProduct,
    selectedSku,
    orderId,
    modifiedPrice,
  } = useAppSelector(state => state.products);

  const { address: propertyAddress } = useAppSelector(state => state.checkout);
  const { root } = useStyles();

  const hideSidebar = useTreatmentNoReadyCheck(HIDE_CHECKOUT_SIDEBAR);

  if (!products || !products.length) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  if (!propertyAddress || !selectedProduct || !selectedSku) {
    history.push("/packages");
    return null;
  }

  if (propertyAddress && selectedProduct && selectedSku && orderId) {
    history.push("/payment-confirmed");
    return null;
  }

  return (
    <AnonymousUserControlWrapper>
      <YZThemeProvider>
        <HeaderBase>
          <DefaultHeader />
        </HeaderBase>
        <Box mt={3} className={root}>
          <Grow in={true}>
            <CheckoutForm
              nextStep={() =>
                history.push("/payment-confirmed", {
                  firstName,
                  lastName,
                  email,
                  selectedSku,
                  orderId,
                  modifiedPrice,
                })
              }
              address={propertyAddress}
              propertyAddress={propertyAddress}
              showSidebar={hideSidebar ? false : true}
            />
          </Grow>
        </Box>
      </YZThemeProvider>
    </AnonymousUserControlWrapper>
  );
};
