import React from "react";
import { Box, makeStyles, Theme, Grid, useMediaQuery } from "@material-ui/core";
import { Page } from "../../types/DesignProfile";
import { ASSETS_BASE_URL } from "../../util/constants/assetsBaseUrl";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import {
  SegmentClickTypes,
  SegmentFlows,
  useSegment,
} from "../../util/Segment";

interface DesignProfileLandingPageProps {
  page: Page;
  onNext: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  packageSummaryRoot: {
    background: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    background: "#000",
    color: "#fff",
    border: 0,
    "&:hover": {
      backgroundColor: "#000!important",
    },
  },
  gridContainer: {
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  fullWidthImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    minHeight: 775,
    [theme.breakpoints.down("xs")]: {
      minHeight: 400,
    },
    objectPosition: "center center",
    padding: 0,
  },
  textAreaWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textArea: {
    paddingTop: 30,
    paddingBottom: 30,
    width: "70%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    textAlign: "left",
    marginLeft: 50,
  },
  title: {
    fontSize: 40,
    paddingBottom: 30,
  },
  subTitle: { paddingBottom: 10 },
}));

export const DesignProfileLandingPage: React.FC<DesignProfileLandingPageProps> = ({
  page,
  onNext,
}) => {
  const classes = useStyles();
  const onlyXS = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const segment = useSegment();

  const sendSegmentTracking = () => {
    segment.trackClicked({
      click_type: SegmentClickTypes.BUTTON,
      flow_name: SegmentFlows.DESIGN_PROFILE,
      button_name: "Get Started",
      button_content: page.button_text || "",
    });
  };

  return (
    <Box pt={onlyXS ? 3 : 1} pb={5} className={classes.packageSummaryRoot}>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={7} style={{ padding: 0 }}>
          <img
            src={`${ASSETS_BASE_URL}/yz-design-profile-hero.png`}
            alt=""
            className={classes.fullWidthImage}
          />
        </Grid>
        <Grid item sm={5} className={classes.textAreaWrapper}>
          <Box className={classes.textArea}>
            <YZTypography color="textSecondary" paragraph>
              {"WE'RE EXCITED TO HELP"}
            </YZTypography>
            <YZTypography className={classes.title} type="serif" variant="h4">
              {page.title}
            </YZTypography>
            <YZTypography paragraph className={classes.subTitle}>
              {
                "Share a little about your unique property, goals, and timeline, and we'll help you select the right package."
              }
            </YZTypography>
            <Box>
              <YZButton
                variant="contained"
                className={classes.button}
                fullWidth={onlyXS}
                onClick={() => {
                  sendSegmentTracking();
                  onNext();
                }}
              >
                {page.button_text}
              </YZButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
