export const ANON_DISPLAY_BANNER = "ANON_DISPLAY_BANNER";
export const USER_DISPLAY_BANNER = "USER_DISPLAY_BANNER";
export const DESIGN_PROFILE_NO_ADDRESS = "DESIGN_PROFILE_NO_ADDRESS";
export const DISPLAY_SMALL_LOT_SIZE_V2 = "DISPLAY_SMALL_LOT_SIZE_V2";
export const DISPLAY_SMALL_LOT_SIZE_AS_DEFAULT_VALUE_V2 =
  "DISPLAY_SMALL_LOT_SIZE_AS_DEFAULT_VALUE_V2";
export const PREMIUM_PACKAGE_FULL_YARD_V2 = "PREMIUM_PACKAGE_FULL_YARD_V2";
export const UBER_PREMIUM_V2 = "UBER_PREMIUM_V2";
export const AA_TEST = "AA_TEST";
export const ANON_HOA_ADD_ON_AT_CHECKOUT = "ANON_HOA_ADD_ON_AT_CHECKOUT";
export const NO_RECOMMEND_ACREAGE_LOT_SIZE = "NO_RECOMMEND_ACREAGE_LOT_SIZE";
export const MARKETING_PHONE_COLLECTION_ONBOARDING =
  "MARKETING_PHONE_COLLECTION_ONBOARDING";
export const MARKETING_PHONE_COLLECTION_DP = "MARKETING_PHONE_COLLECTION_DP";
export const HIDE_CHECKOUT_SIDEBAR = "HIDE_CHECKOUT_SIDEBAR";
export const DESIGN_PROFILE_ONLY_ZIP_TREATMENT =
  "DESIGN_PROFILE_ONLY_ZIP_TREATMENT";
export const SHOW_DESIGN_PROFILE_QUIZ_V9_WITH_MAP =
  "SHOW_DESIGN_PROFILE_QUIZ_V9_WITH_MAP";
export const MOVE_ACCOUNT_CREATION = "MOVE_ACCOUNT_CREATION";

// IMPORTANT:
// Make sure to put your split in the correct traffic type when creating it on split.io!
// Client-dashboard has two traffic types: anonymous (pre-purchase) and user (anything in onboarding)

const anonymousSplits: string[] = [
  AA_TEST,
  ANON_DISPLAY_BANNER,
  DISPLAY_SMALL_LOT_SIZE_V2,
  DISPLAY_SMALL_LOT_SIZE_AS_DEFAULT_VALUE_V2,
  PREMIUM_PACKAGE_FULL_YARD_V2,
  UBER_PREMIUM_V2,
  ANON_HOA_ADD_ON_AT_CHECKOUT,
  NO_RECOMMEND_ACREAGE_LOT_SIZE,
  MARKETING_PHONE_COLLECTION_DP,
  DESIGN_PROFILE_NO_ADDRESS,
  HIDE_CHECKOUT_SIDEBAR,
  DESIGN_PROFILE_ONLY_ZIP_TREATMENT,
  SHOW_DESIGN_PROFILE_QUIZ_V9_WITH_MAP,
  MOVE_ACCOUNT_CREATION,
];

const userSplits: string[] = [
  USER_DISPLAY_BANNER,
  MARKETING_PHONE_COLLECTION_ONBOARDING,
];

export { anonymousSplits, userSplits };
