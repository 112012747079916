import { DesignProfileVersionId } from "../../types/DesignProfile";

// DO NOT EDIT OLD VERSIONS. VERSIONS #'S ARE STORED IN FIRESTORE
// EACH SUBSEQUENT VERSION NEEDS TO INCREMENT IN VERSION # AND NEEDS
// IT'S OWN FILE IN src/designProfileVersions

export const DESIGN_PROFILE_VERSION_1: DesignProfileVersionId = "v1";
// v2: email at end of DP
export const DESIGN_PROFILE_VERSION_2: DesignProfileVersionId = "v2";
// v3: Remove landing page
export const DESIGN_PROFILE_VERSION_3: DesignProfileVersionId = "v3";
// v4: Typeform for quiz pages
export const DESIGN_PROFILE_VERSION_4: DesignProfileVersionId = "v4";
// Version 5: starter can now be recommended from design profile, budget questions updated
export const DESIGN_PROFILE_VERSION_5: DesignProfileVersionId = "v5";
// Version 6: shorter Typeform
export const DESIGN_PROFILE_VERSION_6: DesignProfileVersionId = "v6";
// Version 7: shorter Typeform without address step
export const DESIGN_PROFILE_VERSION_7: DesignProfileVersionId = "v7";
// Version 8: bring back v2 with map, landing page; remove typeform
export const DESIGN_PROFILE_VERSION_8: DesignProfileVersionId = "v8";
// Version 9: moves e-mail entry to first step
export const DESIGN_PROFILE_VERSION_9: DesignProfileVersionId = "v9";
