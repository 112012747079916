import { useEffect } from "react";

interface UseIntercomVisibility {
  (visible: boolean, visibleOnUnmount?: boolean): void;
}

const useIntercomVisibility: UseIntercomVisibility = (
  visible,
  visibleOnUnmount
) => {
  /* Handles the visibility state for the intercom widget and it's 'button'

    On mount intercom visibility will be set based on the "visible" argument, and once
    again on every change of that property. If the visibleOnUnmount argument is supplied,
    intercom visibility will be set to it's value on unmount.
  */

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount(), []);
  useEffect(onVisibilityChange, [visible]);

  function onVisibilityChange() {
    toggleVisibility(visible);
  }

  function onMount() {
    return () => {
      if (typeof visibleOnUnmount === "boolean") {
        toggleVisibility(visibleOnUnmount);
      }
    };
  }

  function toggleVisibility(_visible: boolean) {
    // window.Intercom(_visible ? "show" : "hide");
    window.Intercom("update", { hide_default_launcher: !_visible });
  }
};

export { useIntercomVisibility };
