import * as React from "react";
import { YZTypography } from "@yardzen-inc/react-common";

interface PackageDisplayNameProps {
  packageDisplayName: string;
  hasPromoCodeForUpgrade: boolean;
  variant?: "h2" | "h4";
}

const PackageDisplayName: React.FunctionComponent<PackageDisplayNameProps> = ({
  packageDisplayName,
  hasPromoCodeForUpgrade,
  variant = "h2",
}) => {
  if (!hasPromoCodeForUpgrade) {
    return (
      <YZTypography type="serif" variant={variant}>
        {packageDisplayName}
      </YZTypography>
    );
  }

  const upgradedPackageDisplayName = () => {
    if (packageDisplayName.includes("Back Yard")) {
      return "Full Yard Package";
    }
    if (packageDisplayName.includes("Full Yard")) {
      return "Outdoor Transformation Package";
    }
    if (packageDisplayName.includes("Outdoor Transformation")) {
      return "Yardzen Premium Package";
    }
    return packageDisplayName;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <YZTypography type="serif" variant={variant}>
        <s>{packageDisplayName}</s>
      </YZTypography>
      <YZTypography type="serif" variant={variant}>
        {upgradedPackageDisplayName()}
      </YZTypography>
    </div>
  );
};

export { PackageDisplayName };
export default PackageDisplayName;
