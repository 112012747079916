import { DesignProfileNavigation } from "./types/DesignProfile";
import { designProfileVersion1 } from "./designProfileVersions/designProfileVersion1";
import { designProfileVersion2 } from "./designProfileVersions/designProfileVersion2";
import { designProfileVersion3 } from "./designProfileVersions/designProfileVersion3";
import { designProfileVersion4 } from "./designProfileVersions/designProfileVersion4";
import { designProfileVersion5 } from "./designProfileVersions/designProfileVersion5";
import { designProfileVersion6 } from "./designProfileVersions/designProfileVersion6";
import { designProfileVersion7 } from "./designProfileVersions/designProfileVersion7";
import { designProfileVersion8 } from "./designProfileVersions/designProfileVersion8";
import { designProfileVersion9 } from "./designProfileVersions/designProfileVersion9";

export const designProfilePaths: DesignProfileNavigation[] = [
  designProfileVersion1,
  designProfileVersion2,
  designProfileVersion3,
  designProfileVersion4,
  designProfileVersion5,
  designProfileVersion6,
  designProfileVersion7,
  designProfileVersion8,
  designProfileVersion9,
];
