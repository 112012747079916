import React, { useState, FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { DesignProfileVersionId, Page } from "../../types/DesignProfile";
import { SquareModalBase } from "../../components";
import HelpIconWithText from "../../components/buttons/HelpIconWithText";
import AddressManualEntryForm from "../../components/designProfile/AddressManualEntryForm";
import { useDesignProfileCtx } from "../../components/designProfile";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import { useHistory } from "react-router";
import { DesignProfileStepNumber } from "../../components/designProfile/DesignProfileStepNumber";
import { DesignProfileRouteAccess } from "../../components/designProfile/DesignProfileRouteAccess";
import { getDesignProfilePath } from "./util/getDesignProfilePath";
import { useDispatch } from "react-redux";
import { handleSetAddress } from "./util/handleSetAddress";
import { getPathToFirstQuizPage } from "./util/quizPage/getPathToFirstQuizPage";
import { PlacesAddressInput } from "../../components/map/PlacesAddressInput";
import { getParsedAddressFromPlaceId } from "../../components/map/util/getParsedAddressFromPlaceId";
import { DesignProfileBlackButton } from "../../components/designProfile/DesignProfileBlackButton";
import {
  DESIGN_PROFILE_VERSION_1,
  DESIGN_PROFILE_VERSION_2,
  DESIGN_PROFILE_VERSION_3,
} from "../../util/constants/designProfileVersionIds";
import { handleConfirmAddress } from "./util/handleConfirmAddress";
import {
  SegmentClickTypes,
  SegmentFlows,
  useSegment,
} from "../../util/Segment";
import { useDataLayer } from "../../data";
import { ZipCodeInput } from "../../components/map/ZipCodeInput";
import { handleSetZip } from "./util/handleSetZip";
import { useTreatment } from "@yardzen-inc/react-split";
import { DESIGN_PROFILE_ONLY_ZIP_TREATMENT } from "../../util/Split/splitTreatments";

type AddressInputPageProps = {
  page: Page;
  onNext: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(12),
    },
  },
}));

export const AddressInputPage: FC<AddressInputPageProps> = ({
  page,
  onNext,
}) => {
  const history = useHistory();
  const dataLayer = useDataLayer();
  const segment = useSegment();
  const dispatch = useDispatch();
  const dsContext = useDesignProfileCtx();
  const {
    updateDesignProfile,
    designProfile,
    designProfileVersionId,
  } = dsContext;

  const { paths } = getDesignProfilePath(
    (designProfileVersionId ?? "") as DesignProfileVersionId
  );
  const showSubmitButton =
    designProfileVersionId !== DESIGN_PROFILE_VERSION_1 &&
    designProfileVersionId !== DESIGN_PROFILE_VERSION_2;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [addressSelected, setAddressSelected] = useState<boolean>(false);

  const { root } = useStyles();
  const onlyZip = useTreatment(DESIGN_PROFILE_ONLY_ZIP_TREATMENT);

  return (
    <DesignProfileRouteAccess>
      <Box
        mt={6}
        mb={32}
        mx={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        className={root}
      >
        <Box>
          <YZTypography type="serif" variant="h3" id="where-is-your-space">
            {page.title}
          </YZTypography>
        </Box>
        <Box mt={4}>
          <YZTypography>
            Share a little about your unique property, goals, and timeline, and
            we'll help you select the right design package.
          </YZTypography>
        </Box>
        <Box mt={2} width="100%" display="flex" justifyContent="center">
          {onlyZip && (
            <ZipCodeInput handleZipCodeProvided={handleZipCodeProvided} />
          )}
          {!onlyZip && (
            <PlacesAddressInput
              handleAddressSelection={handleAddressSelection}
            />
          )}
        </Box>
        {!onlyZip && (
          <Box mt={2}>
            <HelpIconWithText onClick={() => setModalOpen(true)}>
              My Address isn't showing up.
            </HelpIconWithText>
            <SquareModalBase
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              keepMounted
            >
              <AddressManualEntryForm
                setOpenHelp={setModalOpen}
                onSubmit={address => handleManualAddressSubmit(address, paths)}
              />
            </SquareModalBase>
          </Box>
        )}
        {showSubmitButton && (
          <Box mt={6}>
            <DesignProfileBlackButton
              text={page.button_text}
              disabled={!addressSelected}
              onClick={() => {
                dataLayer.pushEvent({
                  event: "design_profile_start",
                });
                handleConfirmAddress(onNext, page.button_text);
              }}
              style={{ color: "white" }}
            />
          </Box>
        )}
        {designProfileVersionId === DESIGN_PROFILE_VERSION_1 ||
          designProfileVersionId === DESIGN_PROFILE_VERSION_2 ||
          (designProfileVersionId === DESIGN_PROFILE_VERSION_3 && (
            <Box mt={4}>
              <DesignProfileStepNumber page={page} />
            </Box>
          ))}
      </Box>
    </DesignProfileRouteAccess>
  );

  /**
   * Handle when a user selects an address in the geolocation input
   * @param zipCode validated zip code input from customer
   * @return Promise<void>
   */
  async function handleZipCodeProvided(zipCode: string): Promise<void> {
    try {
      await updateDesignProfile({
        contactInformation: {
          ...designProfile?.contactInformation,
          zip: zipCode,
        },
      } as any);

      handleSetZip({ dispatch, zip: zipCode });
      setAddressSelected(true);

      if (!showSubmitButton) {
        // Move to the next page
        onNext();
      }
    } catch (error) {
      window.newrelic.noticeError(error);
      throw new Error(`Error adding zip to context: ${error} `);
    }
  }

  /**
   * Handle when a user selects an address in the geolocation input
   * @param value Full address info returned from google
   * @return Promise<void>
   */
  async function handleAddressSelection({
    value,
  }: {
    label: string;
    value: any;
  }): Promise<void> {
    try {
      // Add the parsed contactInformation data to context
      const parsedAddress = await getParsedAddressFromPlaceId(value.place_id);

      // We need the cast to any here otherwise typescript will complain about
      // typing for e-mail and lat-lng.
      await updateDesignProfile({
        contactInformation: {
          ...designProfile?.contactInformation,
          ...parsedAddress,
        },
      } as any);

      handleSetAddress({ dispatch, address: parsedAddress });
      setAddressSelected(true);

      if (!showSubmitButton) {
        // Move to the next page
        onNext();
      }
    } catch (error) {
      window.newrelic.noticeError(error);
      throw new Error(`Error adding geolocation to context: ${error} `);
    }
  }

  /**
   * Handle when a user adds their address manual in the "My address
   * isn't showing up" modal.
   * @param geolocation Full geolocation info returned from google
   * @return Promise<void>
   */
  async function handleManualAddressSubmit(
    address: IAddressObject,
    paths: Page[]
  ): Promise<void> {
    try {
      await updateDesignProfile({
        contactInformation: {
          ...designProfile?.contactInformation,
          ...address,
        },
      } as any);

      handleSetAddress({ dispatch, address });

      segment.trackClicked({
        click_type: SegmentClickTypes.BUTTON,
        flow_name: SegmentFlows.DESIGN_PROFILE,
        button_name: "Design Profile Address Confirmation Yes",
        button_content: page.button_text ?? "",
        city: address.city,
        state: address.state,
        zip: address.zip,
      });

      dataLayer.pushEvent({
        event: "design_profile_start",
      });

      // Skip directly to the quiz since no checking
      // location step is required
      history.push(`/design-profile/${getPathToFirstQuizPage(paths)}`);
    } catch (error) {
      window.newrelic.noticeError(error);
      throw new Error(`Error adding address information to context: ${error} `);
    }
  }
};
