import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  LinearProgress,
  Link,
  TextField,
  Theme,
  makeStyles,
} from "@material-ui/core";
import {
  PinterestGTMEventNames,
  TrackingEventCategories,
} from "@yardzen-inc/data-layer";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import "firebase/auth";
import { UserCredential, getAuth, signInAnonymously } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import * as React from "react";
import { useEffect } from "react";
import validator from "validator";
import { banyanAPI } from "../../api/banyanV1API";
import { useDataLayer } from "../../data";
import {
  UserCtx,
  emailRegex,
  useGoogleAnalyticsPageview,
  useUser,
} from "../../util";
import {
  FormFieldFilledProps,
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
  useSegment,
} from "../../util/Segment";
import { firebaseSignOut } from "../../util/cookies/methods";
import hasProfile from "../../util/functions/hasProfile";
import { identifyLogRocketUser } from "../../util/identifyLogRocketUser";
import { sha256ToHexString } from "../../util/sha256";
import { useDesignProfileCtx } from "../designProfile/DesignProfileCtx";
import GenericSnackBar from "../utility/GenericSnackBar";
import ExistingAccountModal from "./ExistingAccountModal";
import GoBackButton from "./GoBack";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { HRError } from "./util/HRError";
import {
  checkLoginProviders,
  linkAccountsWithEmail,
  linkGoogleAccount,
  signInWithEmail,
  signInWithGoogle,
} from "./util/auth";
import { getInitialEmailForCreateAccountForm } from "./util/getInitialEmailForCreateAccountForm";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  content: {
    minWidth: "320px",
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  textContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  text: {
    letterSpacing: "0.23rem",
    fontWeight: "bold",
  },
  goBackContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(4),
  },
  goBackText: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  linkText: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    textDecoration: "underline",
    fontWeight: 500,
  },
}));

interface CreateAccountFormProps {
  goBack: () => void;
  nextStep: () => void;
  noBackButton?: boolean;
  style?: React.CSSProperties;
  noAccountsWithExistingProfile?: boolean;
  disableLogin?: boolean;
  setCheckoutEmail?: React.Dispatch<React.SetStateAction<string | null>>;
  prefilledEmail?: string;
  hideButtonUntilReady?: boolean;
  giftCardRedemption?: boolean;
}

const hasMinimum6CharsRegExp = /.{6,}/;

const CreateAccountForm: React.FC<CreateAccountFormProps> = props => {
  const segment = useSegment();
  const dataLayer = useDataLayer();
  const {
    designProfile,
    user: designProfileUser,
    updateDesignProfile,
  } = useDesignProfileCtx();
  const user = React.useContext(UserCtx);
  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
  const [error, setError] = React.useState<false | string>(false);
  const classes = useStyles();
  const [working, setWorking] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [
    existingAccountModalOpen,
    setExistingAccountModalOpen,
  ] = React.useState<boolean>(false);
  const [agreedToTerms, setAgreedToTerms] = React.useState<boolean>(false);
  const [_error, _user] = useUser();

  const [availableLoginProviders, setAvailableLoginProviders] = React.useState<
    Set<string>
  >(new Set());

  useEffect(() => {
    if (_user) {
      console.log("user", _user);
      console.log(_user.providerData);
    }
  }, [_user]);

  useEffect(() => {
    console.error(_error);
  }, [_error]);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (typeof code === "string") {
      // to be picked up by the checkout form's referral code input
      localStorage.setItem("code", code);
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (email && validator.isEmail(email)) {
        const providers = await checkLoginProviders(email);
        setAvailableLoginProviders(new Set(providers));
      } else {
        setAvailableLoginProviders(new Set());
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [email]);

  const [
    createTermsOfService,
  ] = banyanAPI.useCreateUserTermsOfServiceMutation();

  const { hasMinimum6Chars, passwordError } = React.useMemo(() => {
    const hasMinimum6Chars = password.match(hasMinimum6CharsRegExp);

    const passwordError =
      !hasMinimum6Chars && !!password.length && !isLoggingIn;

    return {
      hasMinimum6Chars,
      passwordError,
    };
  }, [password, isLoggingIn]);

  const emailError: boolean = React.useMemo(() => {
    return !email.match(emailRegex) && !!email.length;
  }, [email]);

  const pageName = window.location.href.includes("design-profile")
    ? !isLoggingIn
      ? "design-profile-create-account-page"
      : "design-profile-login-page"
    : "";

  useGoogleAnalyticsPageview(pageName);

  useEffect(() => {
    if (!email.length) {
      setEmail(
        getInitialEmailForCreateAccountForm({
          prefilledEmail: props.prefilledEmail,
          designProfile,
          user,
        })
      );
    }
  }, [email, props.prefilledEmail, designProfile, user]);

  useEffect(() => {
    // The design profile user can potentially be an empty object, which
    // would incorrectly cause this effect to fire off, so we need this
    // check first to ensure that we have a design profile user that
    // is not just an empty object.
    const shouldCallNextStep =
      !isEmpty(designProfileUser) && !designProfileUser.isAnonymous;

    if (shouldCallNextStep) {
      props.nextStep();
    }
  }, [designProfileUser, props]);

  useEffect(() => {
    if (!!user) {
      logoutAccountsWithExistingProfile(user);
    }
  }, [user]);

  const pwReqs = [{ text: "Six characters", requirement: hasMinimum6Chars }];

  const forgotPw = () => (
    <div>
      <a
        className={classes.linkText}
        href={`https://account${
          process.env.NODE_ENV === "production" ? "" : ".dogfood"
        }.yardzen.com/recover-account`}
      >
        Forgot password?
      </a>
    </div>
  );
  const isSubmitDisabled =
    !isLoggingIn &&
    (working || passwordError || !password.length || !agreedToTerms);
  const termsText = (
    <YZTypography variant="body2">
      By creating an account, I agree to Yardzen's{" "}
      <a
        href="https://yardzen.com/electronic-signature-disclosures"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Consent to Electronic Signature and Disclosures
      </a>
      ,{" "}
      <a
        href="https://yardzen.com/terms-and-conditions"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        href="https://yardzen.com/privacy-policy"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Privacy Policy
      </a>
      .
    </YZTypography>
  );

  const requiresGoogleLogin =
    !!availableLoginProviders.size &&
    !availableLoginProviders.has("password") &&
    availableLoginProviders.has("google.com");

  const googleButtonSection = (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Box flexGrow={1}>
          <Divider></Divider>
        </Box>
        <Box px={2}>
          <YZTypography
            style={{
              color: "#C4C4C4",
              fontSize: "12px",
              letterSpacing: "2px",
            }}
          >
            OR
          </YZTypography>
        </Box>
        <Box flexGrow={1}>
          <Divider></Divider>
        </Box>
      </Box>
      <Box
        py={2}
        pb={1}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Box width="100%">
          <GoogleLoginButton
            trackingData={{
              flow_name: SegmentFlows.CHECKOUT,
            }}
            variant={isLoggingIn ? "login" : "signup"}
            onClick={() => {
              isLoggingIn ? login("google") : onCreateAccount("google");
            }}
          />
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <ExistingAccountModal
        open={existingAccountModalOpen}
        onClose={async () => {
          await firebaseSignOut();
          setExistingAccountModalOpen(false);
          setIsLoggingIn(false);
        }}
      />
      <Box className={classes.root} style={props.style}>
        <Box
          className={classes.content}
          component="form"
          onSubmit={handleSubmit}
        >
          {!props.noBackButton && (
            <Box mb={3}>
              <GoBackButton goBack={props.goBack} />
            </Box>
          )}
          <Box className={classes.textContainer}>
            <YZTypography variant="h3" type="serif">
              {isLoggingIn
                ? props.giftCardRedemption
                  ? "Already have an account?"
                  : "Login to pick up where you left off"
                : "First, let's get you an account"}
            </YZTypography>
          </Box>
          <Box mb={3}>
            <TextField
              id="email-account-creation-input"
              label="Email"
              error={emailError}
              value={email}
              disabled={working}
              onChange={handleEmailChange}
              onBlur={getSegmentBlurHandler({
                field_name: "Email",
                form_name: SegmentForms.CHECKOUT_ACCOUNT_SIGNUP,
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              })}
              required
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box mb={3}>
            <TextField
              id="password-creation-input"
              label="password"
              error={passwordError}
              value={password}
              helperText={
                requiresGoogleLogin && isLoggingIn
                  ? "Your account uses google login!"
                  : undefined
              }
              disabled={(requiresGoogleLogin && isLoggingIn) || working}
              autoComplete="current-password"
              onChange={handlePasswordChange}
              onBlur={
                password
                  ? getSegmentBlurHandler({
                      field_name: "Password",
                      form_name: SegmentForms.CHECKOUT_ACCOUNT_SIGNUP,
                      flow_name: SegmentFlows.CHECKOUT,
                      input_type: SegmentInputTypes.TEXT,
                    })
                  : undefined
              }
              type="password"
              variant="outlined"
              required
              fullWidth
            />
          </Box>
          {passwordError && (
            <>
              <Box display="flex">
                <YZTypography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: 500 }}
                >
                  Your password must have at least:
                </YZTypography>
              </Box>
              <ul>
                {pwReqs.map(req => (
                  <li key={req.text}>
                    <YZTypography
                      style={{ color: req.requirement ? "#ABC057" : "#C05757" }}
                      variant="body2"
                    >
                      {req.text}
                    </YZTypography>
                  </li>
                ))}
              </ul>
            </>
          )}
          <Box display="flex" flexDirection="column" width="100%">
            {!isLoggingIn && (
              <>
                <TosCheckbox
                  setAgreedToTerms={setAgreedToTerms}
                  agreedToTerms={agreedToTerms}
                  termsText={termsText}
                />
              </>
            )}

            <Box
              display={
                props.hideButtonUntilReady && passwordError ? "none" : void 0
              }
              mt={1}
              pb={2}
            >
              <YZButton
                id={isLoggingIn ? "loginButton" : "createAccountButton"}
                type="submit"
                disabled={isSubmitDisabled}
                color="primary"
                fullWidth
              >
                {isLoggingIn ? "Login" : "Create Account"}
              </YZButton>
              {working && <LinearProgress variant="indeterminate" />}
            </Box>

            {googleButtonSection}
          </Box>
          {!!error && (
            <GenericSnackBar
              onClose={() => setError(false)}
              variant="error"
              message={error}
            />
          )}

          {!props.disableLogin && (
            <YZTypography
              variant="h6"
              style={{ textAlign: "center", marginTop: "1.5rem" }}
              color="textSecondary"
            >
              {isLoggingIn ? (
                <>
                  Don't have an account?{" "}
                  <Link
                    onClick={() => setIsLoggingIn(false)}
                    className={classes.linkText}
                  >
                    Sign up
                  </Link>
                  {forgotPw()}
                </>
              ) : (
                <>
                  Already have an account?{" "}
                  <Link
                    onClick={() => setIsLoggingIn(true)}
                    className={classes.linkText}
                  >
                    Login
                  </Link>
                  {forgotPw()}
                </>
              )}
            </YZTypography>
          )}
        </Box>
      </Box>
    </>
  );

  function getSegmentBlurHandler(segmentPayload: FormFieldFilledProps) {
    return () => segment.trackFormFieldFilled(segmentPayload);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    console.error("THIS SHOULD NOT HAVE HAPPENED");
    e.preventDefault();

    segment.trackFormSubmitted({
      email,
      state: designProfile?.contactInformation?.state,
      city: designProfile?.contactInformation?.city,
      zip: designProfile?.contactInformation?.zip,
      flow_name: SegmentFlows.CHECKOUT,
      form_name: SegmentForms.CHECKOUT_ACCOUNT_SIGNUP,
      passed_validation: !passwordError && !!password.length,
    });

    if (!isLoggingIn && (working || passwordError || !password.length)) {
      return;
    }

    void (isLoggingIn ? login : onCreateAccount)("email");
  }

  async function login(method: "google" | "email" = "email") {
    setWorking(true);

    try {
      let user: UserCredential | undefined;

      switch (method) {
        case "email":
          user = await signInWithEmail(email, password);
          break;
        case "google":
          user = await signInWithGoogle();
          break;
        default:
          throw new Error(`Invalid method: ${method}`);
      }

      if (
        !!user?.user &&
        props.noAccountsWithExistingProfile &&
        !!(await hasProfile(user?.user?.uid))
      ) {
        setExistingAccountModalOpen(true);
      } else {
        segment.trackAccountCreated({
          email,
          fbp: Cookies.get("_fbp") || null,
          fbc: Cookies.get("_fbc") || null,
        });

        if (props.setCheckoutEmail) {
          props.setCheckoutEmail(email);
        }
        props.nextStep();
      }
    } catch (error) {
      window.newrelic.noticeError(error);
      console.error(error);

      setPassword("");
      if (error instanceof HRError) {
        setError(error.displayMessage);
      } else {
        setError(error.message);
      }
    } finally {
      setPassword("");
      setWorking(false);
    }
  }

  function handleEmailChange({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setEmail(value.trim());
  }

  function handlePasswordChange({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>): void {
    setPassword(value);
  }

  async function onCreateAccount(method: "google" | "email" = "email") {
    setWorking(true);

    const auth = getAuth();
    let successCreatingAccount = true;
    let user = await (auth.currentUser ||
      signInAnonymously(auth).then(u => u.user!));
    let userId = user.uid;
    let _email = email;

    try {
      switch (method) {
        case "google":
          await linkGoogleAccount(user);
          await user.reload();
          user = auth.currentUser!;
          console.debug(user);
          _email = user.email || email;
          break;
        case "email":
          await linkAccountsWithEmail(user, _email, password);
          await user.reload();
          user = auth.currentUser!;
          break;
        default:
          throw new Error(`Invalid method: ${method}`);
      }

      if (!designProfileUser.uid) {
        identifyLogRocketUser({ userId, email: _email || undefined });
        if (props.setCheckoutEmail) {
          props.setCheckoutEmail(_email);
        }

        props.nextStep();
      } else {
        if (designProfile) {
          await updateDesignProfile({
            userId,
            contactInformation: {
              ...designProfile?.contactInformation,
              email: _email,
            },
          });
        }

        props.nextStep();
      }
    } catch (error) {
      successCreatingAccount = false;
      handleCreateAccountError(error);
    }

    await handleTrackCreateAccount(_email, successCreatingAccount, userId);
  }

  function handleCreateAccountError(error: any) {
    console.error(error);
    window.newrelic.noticeError(error);

    if (error instanceof HRError) {
      setError(error.displayMessage);
    } else {
      setError("An error occurred while creating your account.");
    }

    setPassword("");
    setWorking(false);
  }

  async function handleTrackCreateAccount(
    _email: string,
    successCreatingAccount: boolean,
    userId: string
  ) {
    dataLayer.tracking_id = await sha256ToHexString(
      _email.trim().toLowerCase()
    );
    if (successCreatingAccount) {
      segment.trackAccountCreated({
        email: _email,
        fbp: Cookies.get("_fbp") || null,
        fbc: Cookies.get("_fbc") || null,
      });
      if (userId) {
        try {
          await createTermsOfService({
            userId: userId,
            tosAgreed: agreedToTerms,
            dateOfAgree: new Date().toISOString(),
          });
        } catch (error) {
          console.error("Error saving terms of service agreement", error);
        }
      }
    }
    dataLayer.recordTrackingEvent(PinterestGTMEventNames.SIGNUP, {
      action: "Create Account",
      label: window.location.href,
      category: TrackingEventCategories.ACCOUNT,
      value: null,
      hashedEmail: dataLayer.tracking_id,
    });
  }

  async function logoutAccountsWithExistingProfile(user: firebase.User) {
    if (await hasProfile(user?.uid)) {
      setExistingAccountModalOpen(true);
    }
  }
};

function TosCheckbox({
  setAgreedToTerms,
  termsText,
  agreedToTerms,
}: {
  setAgreedToTerms: React.Dispatch<React.SetStateAction<boolean>>;
  agreedToTerms: boolean;
  termsText: JSX.Element;
}) {
  return (
    <Box pb={1}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={agreedToTerms}
            onChange={e => setAgreedToTerms(e.target.checked)}
            id="tos-checkbox"
          />
        }
        label={termsText}
      />
    </Box>
  );
}

export { CreateAccountForm };
export default CreateAccountForm;
