import * as React from "react";
import {
  Box,
  useMediaQuery,
  Theme,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import {
  useScrollTop,
  useGoogleAnalyticsPageview,
  smartRedirect,
  UserCtx,
  AppVersion,
} from "../../util";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { YZTypography, YZButton } from "@yardzen-inc/react-common";
import { usePreventNavigation } from "../../util/hooks/usePreventNavigate";
import { activateNextdoorPixel } from "../../util/nextDoor";
import {
  PinterestGTMEventNames,
  TrackingEventCategories,
} from "@yardzen-inc/data-layer";
import { sha256ToHexString } from "../../util/sha256";
import { useDataLayer } from "../../data";
import DesignProfileLayout from "../designProfile/DesignProfileLayout";
import { SegmentFlows, useSegment } from "../../util/Segment";
import { ASSETS_BASE_URL } from "../../util/constants/assetsBaseUrl";
import { PriceObject } from "../../types/Prices";
import { useAppSelector } from "../../hooks";
import {
  hasAddOnExpedited,
  hasAddOnHOAExtraRevision,
} from "../../util/functions/hasAddOn";
import { ItemizedPaymentDetail } from "./ItemizedPaymentDetail";
import { usePremiumPackage } from "../../util/hooks/usePremiumPackage";
import {
  CURB_APPEAL_DISPLAY_URL,
  OUTDOOR_TRANSFORMATION_DISPLAY_URL,
  SMALL_SPACE_DISPLAY_URL,
  UBER_PREMIUM_DISPLAY_URL,
  displayNameForStripePackage,
  BOTANICAL_PACKAGE,
  FULL_YARD_PACKAGE,
  CURB_APPEAL_PACKAGE,
  OUTDOOR_TRANSFORMATION_PACKAGE,
  FRONT_YARD_PACKAGE,
  BACK_YARD_PACKAGE,
  UBER_PREMIUM_PACKAGE,
  SMALL_SPACE_PACKAGE,
  FRONT_OR_BACKYARD_STARTER_PACKAGE,
  ENTIRE_YARD_STARTER_PACKAGE,
  STARTER_FULL_YARD_DISPLAY_URL,
  STARTER_FRONT_OR_BACKYARD_DISPLAY_URL,
  LOWES_PACKAGE,
} from "../../util/constants/packageTypes";
import { useImpactRadiusConversion } from "../../util/hooks/useImpactRadiusConversion";
import { useTikTokTrackPaymentComplete } from "../../util/hooks/useTikTokTrackPaymentComplete";
import { formatPrice } from "./util/formatPrice";
import { DiscountDetail } from "./DiscountDetail";
import { useFriendBuyPaymentComplete } from "../../util/hooks/useFriendBuyPaymentComplete";
import Cookies from "js-cookie";
import { useLotSizeDisplayText } from "../../util/useLotSizeDisplayText";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import PackageDisplayName from "./PackageDisplayName";
import { promoCodeIsForUpgrade } from "./util/promoCodeIsForUpgrade";

const useStyles = makeStyles((theme: Theme) => ({
  clientContainer: {
    padding: theme.spacing(2),
    borderBottom: "1px solid rgba(0,0,0,.05)",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      "& p": {
        width: "50%",
        padding: "4px",
      },
    },
  },
  clientLabel: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  divider: {
    background: "rgba(0,0,0,.05)",
  },
  image: {
    width: "350px",
    height: "280px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 200,
    },
  },
  mainInfo: {
    justifyContent: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  mainInfoItem: {
    padding: "12px 24px",
    "&:not(:last-child)": {
      borderRight: "1px solid #eee",
    },
    [theme.breakpoints.down("sm")]: {
      borderRight: 0,
      borderBottom: "1px solid #eee",
    },
  },
  orderID: {
    fontWeight: 500,
  },
}));

export interface PaymentConfirmedProps {
  goBack?: () => void;
  skuObject: PriceObject;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  orderId: string | null;
  modifiedPrice: null | number;
  address: IAddressObject;
}

// Not all of these images correspond to the images in packageTypes.ts
const images: Record<string, string> = {
  [BOTANICAL_PACKAGE]: `${ASSETS_BASE_URL}/public/botanical.jpg`,
  [FULL_YARD_PACKAGE]: `${ASSETS_BASE_URL}/public/botanical_top.jpg`,
  [CURB_APPEAL_PACKAGE]: CURB_APPEAL_DISPLAY_URL,
  [OUTDOOR_TRANSFORMATION_PACKAGE]: OUTDOOR_TRANSFORMATION_DISPLAY_URL,
  [FRONT_YARD_PACKAGE]: `${ASSETS_BASE_URL}/public/front_yard_1.png`,
  [BACK_YARD_PACKAGE]: `${ASSETS_BASE_URL}/public/back_yard_1.png`,
  premium: `${ASSETS_BASE_URL}/public/recommended-package-premium.jpg`,
  "additional revision": `${ASSETS_BASE_URL}/public/additional_revision.jpg`,
  [UBER_PREMIUM_PACKAGE]: UBER_PREMIUM_DISPLAY_URL,
  [SMALL_SPACE_PACKAGE]: SMALL_SPACE_DISPLAY_URL,
  [FRONT_OR_BACKYARD_STARTER_PACKAGE]: STARTER_FRONT_OR_BACKYARD_DISPLAY_URL,
  [ENTIRE_YARD_STARTER_PACKAGE]: STARTER_FULL_YARD_DISPLAY_URL,
  [LOWES_PACKAGE]: `${ASSETS_BASE_URL}/public/lowes.png`,
};

const PaymentConfirmed: React.FC<PaymentConfirmedProps> = props => {
  const segment = useSegment();
  const dataLayer = useDataLayer();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const todaysDate = moment().format("MMMM D, YYYY");

  const user = React.useContext(UserCtx);

  const lotSize = useLotSizeDisplayText(props.skuObject.attributes.name);
  const [projectDocReady, setProjectDocReady] = React.useState(false);
  const [profileDocReady, setProfileDocReady] = React.useState(false);
  const [readyToProceed, setReadyToProceed] = React.useState(false);
  const [statusDocNotDetected, setStatusDocNotDetected] = React.useState(false);
  const [docCreationError, setDocCreationError] = React.useState(false);
  const { addOnsTotal, addOnSkus, packageStyle } = useAppSelector(
    state => state.products
  );
  const expeditedSku = addOnSkus.find(addOn => hasAddOnExpedited({ addOn }));
  const hoaExtraRevisionSku = addOnSkus.find(addOn =>
    hasAddOnHOAExtraRevision({ addOn })
  );
  const { promotionalCode } = useAppSelector(state => state.checkout);
  const hasPromoCodeForUpgrade =
    (promotionalCode && promoCodeIsForUpgrade(promotionalCode)) ?? false;
  const { isPremium, premiumSku } = usePremiumPackage();

  const additionalDiscounts = promotionalCode?.additionalDiscounts?.reduce(
    (acc, discount) => acc + parseInt(String(discount ?? "1")),
    0
  );

  const hasAnyPromoCodeDiscount =
    parseInt(promotionalCode?.discount ?? "1") !== 0 ||
    additionalDiscounts !== 0;

  const pageName = window.location.href.includes("design-profile")
    ? "design-profile-payment-confirmation-step"
    : "packages-payment-confirmation-step";

  const isLowesPackage = props.skuObject.attributes.name.includes("Lowes");
  useGoogleAnalyticsPageview(pageName);
  useScrollTop([]);

  usePreventNavigation(!readyToProceed);

  const handleRedirect = ({
    version,
    path,
  }: {
    version: AppVersion;
    path: string;
  }) => {
    smartRedirect(version, path);
  };
  const stripeProductMetadataPackage = props.skuObject.metadata.product;
  const packageName = displayNameForStripePackage[stripeProductMetadataPackage];

  const packageDisplayName: string = React.useMemo(() => {
    if (!stripeProductMetadataPackage) {
      console.error(
        `Missing metadata package name. Check the Stripe Product ${props.skuObject.product} metadata for 'product'`
      );
    }

    if (isLowesPackage) {
      return `MyLowe's Rewards + Yardzen Design Package`;
    }

    if (packageStyle) return packageStyle;

    if (isPremium) {
      return `Yardzen Premium - ${packageName}`;
    }

    return packageName.replace("Back Yard", "Backyard");
  }, [
    stripeProductMetadataPackage,
    isLowesPackage,
    packageStyle,
    isPremium,
    packageName,
    props.skuObject.product,
  ]);

  const lotSizeDisplayName: string = React.useMemo(() => {
    if (isLowesPackage) {
      if (packageName.includes("Full Yard")) {
        return "Full Yard";
      }
      return "Front or Backyard";
    }
    return lotSize;
  }, [packageName, lotSize, isLowesPackage]);

  useImpactRadiusConversion({
    orderId: props.orderId ?? "",
    customerEmail: props.email ?? "",
    packageSubTotal: props.modifiedPrice ?? props.skuObject.price,
    packageSku: props.skuObject,
    packageDisplayName: packageDisplayName,
    expeditedSku: expeditedSku,
    premiumSku: premiumSku,
    isPremium: isPremium,
  });

  useTikTokTrackPaymentComplete({
    isPremium,
    sku: props.skuObject,
    premiumSku,
    expeditedSku,
  });

  useFriendBuyPaymentComplete({
    customerEmail: props.email,
    expeditedSku,
    isPremium,
    orderId: props.orderId || undefined,
    orderTotal: props.modifiedPrice,
    packageDisplayName,
    packageSku: props.skuObject,
    premiumSku,
    referralCode: promotionalCode?.code ?? "",
  });

  React.useEffect(activateNextdoorPixel, []);
  React.useEffect(() => {
    if (user) {
      trackPurchase();
    }
    // TODO: remove disable comment and fix warning next time this hook is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (user) {
      subscribeToStatusDoc(user.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (projectDocReady && profileDocReady) {
      setReadyToProceed(true);

      const updatePkgStyleInUserDoc = async () => {
        async function getAuthenticatedHeaders() {
          const headers = new Headers();
          headers.set(
            "Authorization",
            `Bearer ${await firebase.auth()?.currentUser?.getIdToken()}`
          );
          headers.set("Content-Type", "application/json");

          return headers;
        }

        return await fetch(
          `${process.env["REACT_APP_PANGAEA_BASE_URL"] ||
            "http://localhost:7777/v1/"}profiles/lifestyle-package/${
            user?.uid
          }`,
          {
            method: "PATCH",
            headers: await getAuthenticatedHeaders(),
            body: JSON.stringify({ packageStyle }),
          }
        );
      };

      if (user?.uid) updatePkgStyleInUserDoc();
    }
  }, [projectDocReady, profileDocReady, user?.uid, packageStyle]);

  const productIsAdditionalRevision =
    props.skuObject.metadata.product === "additional revision";

  const segmentFlow = productIsAdditionalRevision
    ? SegmentFlows.PURCHASE_ADDITIONAL_REVISION
    : SegmentFlows.CHECKOUT;

  return (
    <DesignProfileLayout
      title="Thank you for your order!"
      subtitle={
        productIsAdditionalRevision
          ? ""
          : "Please stay on this page while we set up your account"
      }
    >
      <Box mt={4}>
        <Grid container spacing={3} className={classes.mainInfo}>
          <Grid item>
            <YZTypography
              type="uppercase"
              variant="body2"
              color="textSecondary"
            >
              Order Date
            </YZTypography>
            <YZTypography>{todaysDate}</YZTypography>
          </Grid>
          {props.orderId && (
            <Grid item color="textSecondary">
              <YZTypography
                type="uppercase"
                variant="body2"
                color="textSecondary"
              >
                Order ID
              </YZTypography>
              <YZTypography
                id="orderIdTag"
                variant="body1"
                className={classes.orderID}
              >
                {props.orderId}
              </YZTypography>
            </Grid>
          )}
          {props.email && (
            <Grid item>
              <YZTypography
                type="uppercase"
                variant="body2"
                color="textSecondary"
              >
                Confirmation will be sent to
              </YZTypography>
              <YZTypography>{props.email}</YZTypography>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={smDown ? 2 : 5}>
          <Grid item md={6} style={smDown ? { width: "100%" } : undefined}>
            <img
              src={getImageUrl(
                isPremium ? "premium" : props.skuObject.metadata.product
              )}
              alt="package"
              width="100%"
              className={classes.image}
              style={{ borderRadius: 4 }}
            />
          </Grid>
          <Grid item md={6} style={smDown ? { width: "100%" } : undefined}>
            <Box>
              <YZTypography type="uppercase" variant="caption">
                {productIsAdditionalRevision ? "Your Purchase" : "Your Package"}
              </YZTypography>
              <PackageDisplayName
                packageDisplayName={packageDisplayName}
                hasPromoCodeForUpgrade={hasPromoCodeForUpgrade}
                variant="h2"
              />
              <YZTypography variant="h6" color="textSecondary">
                {lotSizeDisplayName}
              </YZTypography>
            </Box>
            <ItemizedPaymentDetail title="Subtotal">
              <YZTypography variant="body1" style={{ fontWeight: 600 }}>
                {formatPrice(
                  props.skuObject.price + (isPremium ? premiumSku.price : 0)
                )}
              </YZTypography>
            </ItemizedPaymentDetail>
            {expeditedSku && (
              <ItemizedPaymentDetail title="Expedited Upgrade">
                <YZTypography variant="body1" style={{ fontWeight: 600 }}>
                  {formatPrice(expeditedSku.price)}
                </YZTypography>
              </ItemizedPaymentDetail>
            )}
            {hoaExtraRevisionSku && (
              <ItemizedPaymentDetail title="HOA add-on">
                <YZTypography variant="body1" style={{ fontWeight: 600 }}>
                  {formatPrice(hoaExtraRevisionSku.price)}
                </YZTypography>
              </ItemizedPaymentDetail>
            )}
            {hasAnyPromoCodeDiscount && (
              <DiscountDetail
                discountPrice={props.modifiedPrice}
                skuObject={props.skuObject}
              />
            )}
            <ItemizedPaymentDetail title="Total">
              <YZTypography variant="body1" style={{ fontWeight: 600 }}>
                <span id="skuPriceTag">
                  {!props.modifiedPrice || props.modifiedPrice === 0
                    ? formatPrice(props.skuObject.price + addOnsTotal)
                    : formatPrice(props.modifiedPrice)}
                </span>
              </YZTypography>
            </ItemizedPaymentDetail>
          </Grid>
        </Grid>
        {productIsAdditionalRevision ? (
          <Box mt={smDown ? 2 : 4} display="flex" justifyContent="center">
            <YZButton
              onClick={() =>
                handleRedirect({ version: "v1", path: "tracker/finalize" })
              }
              color="primary"
            >
              GIVE FEEDBACK ON DESIGN
            </YZButton>
          </Box>
        ) : (
          <Box mt={smDown ? 2 : 4} display="flex" justifyContent="center">
            {readyToProceed && !docCreationError ? (
              <YZButton
                id="redirect-user-account-YZ-Button"
                onClick={() =>
                  handleRedirect({ version: "v2", path: "onboard" })
                }
                color="primary"
              >
                GO TO MY PROJECT DASHBOARD
              </YZButton>
            ) : (
              renderAccountStatusText()
            )}
          </Box>
        )}
      </Box>
      {shareASalePixel(props.orderId, props.skuObject.price + addOnsTotal)}
      {twitterPixel()}
    </DesignProfileLayout>
  );

  function renderAccountStatusText() {
    if (docCreationError) {
      return (
        <YZTypography color="primary">
          We've received your payment. We can’t wait to get started on your
          project! Watch out for an email from us with instructions on next
          steps. Reach out to support@yardzen.com at any time with questions.
        </YZTypography>
      );
    } else {
      return (
        <Box display="flex" alignItems="center">
          <CircularProgress />
          <YZTypography
            style={{
              paddingLeft: "1rem",
              letterSpacing: 0,
              fontSize: "0.9rem",
            }}
          >
            {!projectDocReady && !profileDocReady
              ? "We're setting up your account..."
              : "Putting the finishing touches on your account..."}
          </YZTypography>
        </Box>
      );
    }
  }

  function getImageUrl(packageName: string) {
    if (isLowesPackage) {
      return images[LOWES_PACKAGE];
    }

    return images[packageName];
  }

  async function subscribeToStatusDoc(userId: string) {
    statusDocErrorTimer();
    return await firebase
      .firestore()
      .collection("newPackageOrderStatuses")
      .where("userId", "==", userId)
      .orderBy("createdAt", "desc")
      .onSnapshot(snap => {
        if (snap.empty && !statusDocNotDetected) {
          setStatusDocNotDetected(true);
        } else {
          setStatusDocNotDetected(false);
          const data = snap.docs[0].data();
          setLocalDocumentState(data);
        }
      });
  }

  function statusDocErrorTimer() {
    setTimeout(() => {
      if (statusDocNotDetected) {
        setDocCreationError(true);
      }
    }, 10000);
  }

  function setLocalDocumentState(data: firebase.firestore.DocumentData) {
    if (!!data.profileDocCreated) {
      setProfileDocReady(true);
    }

    if (!!data.projectDocCreated) {
      setProjectDocReady(true);
    }

    if (
      [
        data.profileDocCreated,
        data.projectDocCreated,
        data.profileDocCreated,
      ].indexOf(false) !== -1
    ) {
      setDocCreationError(true);
    }
  }

  function trackPurchase() {
    void (async function() {
      const email = await sha256ToHexString(props.email.trim().toLowerCase());
      const orderId = props.orderId;
      const sku = props.skuObject.id;
      const price = props.modifiedPrice ?? props.skuObject.price;
      const floatPrice = parseFloat((price / 100).toFixed(2)); // HACK

      dataLayer.recordTrackingEvent(PinterestGTMEventNames.CHECKOUT, {
        label: packageDisplayName,
        action: "Purchase - Confirmed",
        category: TrackingEventCategories.CONVERSION,
        value: price,
        hashedEmail: email,
        productCategory: "Landscape Design Package",
        orderId: orderId ?? "",
        lineItems: [
          {
            product_name: packageDisplayName,
            product_id: sku,
            product_price: floatPrice,
            product_quantity: 1,
          },
        ],
        packagePrice: floatPrice,
        packageSKU: sku,
        packageStyle: packageStyle,
      });

      segment.trackConversion({
        client_reported: true,
        conversion_name: "Payment Confirmed",
        flow_name: segmentFlow,
        amount: price + addOnsTotal,
        product_name: props.skuObject.metadata.product,
        product_details: props.skuObject.attributes.name,
        is_premium: isPremium,
        fbp: Cookies.get("_fbp") || null,
        fbc: Cookies.get("_fbc") || null,
        email: props.email,
        phone: props.phone,
        first_name: props.firstName,
        last_name: props.lastName,
        city: props.address.city,
        state: props.address.state,
        zip: props.address.zip,
        street: props.address.street,
        package_style: packageStyle,
      });

      segment.trackFlowCompleted({
        flow_name: segmentFlow,
      });
    })();
  }

  function shareASalePixel(orderId: string | null, totalPrice: number) {
    return (
      <img
        src={`https://www.shareasale.com/sale.cfm?tracking=${orderId}&amount=${totalPrice /
          100}&merchantID=108397&transtype=sale`}
        width="1"
        height="1"
        alt="shareasale"
      />
    );
  }

  function twitterPixel() {
    return (
      <>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://analytics.twitter.com/i/adsct?txn_id=o90h3&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0"
        />
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="//t.co/i/adsct?txn_id=o90h3&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0"
        />
      </>
    );
  }
};

export { PaymentConfirmed };
export default PaymentConfirmed;
